<h1 *ngIf="(!!userId || forceEnroll) && isDialogView" mat-dialog-title>Multi-Factor Authentication</h1>
<mat-dialog-content *ngIf="!!userId || forceEnroll">
  <div class="mfa-container">
    <h1 *ngIf="!isDialogView" [ngClass]="{ 'small-header': isSmallScreen }" class="title">
      Multi-Factor Authentication
    </h1>

    <div class="banner-img-container zero">
      <img class="banner-img" alt="MFA banner" src="{{ bannerImg }}" />
    </div>

    <div *ngIf="accessToken !== ''">
      <div *ngIf="forceEnroll">
        <div class="welcome-message-container text-left">
          <p>
            Your organisation requires multi-factor authentication, which is an added layer of security.
            If you have questions, you can <a rel="noopener noreferrer"
              href="https://www.agilicus.com/about-multi-factor-authentication/" target="_blank">learn more.</a>
          </p>
          <p>
            We'll help you get set up. The first step is picking a multi-factor
            authentication method from the list below.
          </p>
        </div>
      </div>

      <div class="expansion-container">
        <mat-accordion multi>
          <mat-expansion-panel
            [disabled]="!isSupportedMfaMethod(challengeType.web_push)"
            [matTooltip]="restrictedMethodDisabledPanelTooltipText"
            [matTooltipDisabled]="isSupportedMfaMethod(challengeType.web_push)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="scrollable-div">
                <mat-icon class="mfa-icon">notifications</mat-icon>
                Web-Push Notification
                <mat-icon class="help-icon" [matTooltip]="webPushTooltipText">help_outline</mat-icon>
              </mat-panel-title>
              <mat-panel-description *ngIf="isEnrolled(webPushDeviceTableData)">
                <mat-icon class="enabled-icon">check_circle</mat-icon>
                <span *ngIf="!isSmallScreen">Enabled</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div *ngIf="canUseWebPush()" class="scrollable-div">
                <p class="text-left">
                  Note that you should use an alternate method if this is a shared
                  computer. If not, then continue with the setup. When you click
                  <b>{{ getActiveWebPushEnrollButtonText() }}</b> you will be asked by your browser to allow
                  notifications. It is important that you answer <b>ALLOW</b> to
                  this.
                </p>
              </div>

              <div *ngIf="!canUseWebPush()">
                Error: this browser does not support web push notification.
              </div>

              <div *ngIf="canUseWebPush()" class="scrollable-div">
                <button mat-raised-button color="primary" class="set-up-button" (click)="subscribeToNotifications()">
                  {{ getActiveWebPushEnrollButtonText() }}
                </button>
              </div>

              <div *ngIf="enrollMessageSent" class="notification-confirmation-container">
                <p>Did you receive a notification?</p>

                <div class="confirmation-buttons-container">
                  <div class="confirmation-button-container">
                    <button mat-raised-button color="primary" class="confirmation-button" (click)="onNoClick()">
                      NO
                    </button>
                  </div>
                  <div class="confirmation-button-container">
                    <button mat-raised-button color="primary" class="confirmation-button" (click)="onYesClick()">
                      YES
                    </button>
                  </div>
                </div>
              </div>

              <div class="device-table-container">
                <div class="device-table-title">
                  <h3>Enrolled Devices:</h3>
                </div>

                <div class="mat-elevation-z8">
                  <table mat-table #webPushTableSort="matSort" [dataSource]="webPushDataSource" matSort>
                    <ng-container matColumnDef="created">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Date Added
                      </th>
                      <td mat-cell *matCellDef="let row">
                        {{ row.created }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="enabled">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Enabled
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.enabled" class="enabled-icon">check_circle</mat-icon>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="actions-column-header">
                        Actions
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-icon-button [matMenuTriggerFor]="menu"
                          aria-label="Icon-button with a menu to choose an action for the selected row"
                          matTooltip="Click to select an option">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="testWebPushChallengeMethodFromTable(row)">
                            <mat-icon>notifications</mat-icon>
                            <span>Test</span>
                          </button>
                          <button mat-menu-item (click)="deleteWebPushChallengeMethodFromTable(row)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                          </button>
                        </mat-menu>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="webPushDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: webPushDisplayedColumns"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            [disabled]="!isSupportedMfaMethod(challengeType.totp)"
            [matTooltip]="restrictedMethodDisabledPanelTooltipText"
            [matTooltipDisabled]="isSupportedMfaMethod(challengeType.totp)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="scrollable-div">
                <mat-icon class="mfa-icon">phonelink</mat-icon>
                Authenticator App
                <mat-icon class="help-icon" [matTooltip]="authAppTooltipText">help_outline</mat-icon>
              </mat-panel-title>
              <mat-panel-description>
                <ng-container *ngIf="allowReset()">
                  <mat-icon class="enabled-icon">check</mat-icon>
                  <span *ngIf="!isSmallScreen">Enrolled</span>
                </ng-container>
                <ng-container *ngIf="isAuthAppEnabled()">
                  <mat-icon class="enabled-icon">check_circle</mat-icon>
                  <span *ngIf="!isSmallScreen">Enabled</span>
                </ng-container>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!isTotpEnrolled()">
              <button *ngIf="!totpUrl" mat-raised-button color="primary" class="confirmation-button" (click)="addTotp()">
                SET UP AUTHENTICATOR
              </button>

              <div *ngIf="totpUrl"  class="scrollable-div">
                <p class="text-left">
                  Either scan the QR code using your authenticator app, or copy the
                  codes below the QR code into your authenticator app.
                </p>
                <p class="text-left">
                  You may be able to set this up automatically - you can
                  <a [href]="getTotpUrl()">click here to try</a>. If not, simply follow the
                  instructions below to set up manually.
                </p>

                <div class="qr-code-container">
                  <qrcode *ngIf="!isSmallScreen" [qrdata]="totpUrl" [width]="384" [errorCorrectionLevel]="'M'"></qrcode>
                  <qrcode *ngIf="isSmallScreen" [qrdata]="totpUrl" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                </div>

                <h3>OR</h3>

                <h2 class="tptp-key">{{ totpKey }}</h2>

                <p>
                  Then enter <b>the code generated by the authenticator app</b> into
                  the box below, and click <b>VERIFY</b>.
                </p>

                <mat-form-field class="verification-input" appearance="outline">
                  <input matInput [(ngModel)]="totpResponseKey" (keyup.enter)="enrollDeviceWithTotp()" spellcheck="false"
                    autocomplete="off" />
                </mat-form-field>

                <button mat-raised-button color="primary" class="confirmation-button" (click)="enrollDeviceWithTotp()">
                  VERIFY
                </button>
              </div>
            </div>
            <div *ngIf="isTotpEnrolled()" class="delete-button-container">
              <button mat-raised-button color="warn" class="delete-button" (click)="deleteTotpChallengeMethod()">
                DELETE
              </button>
              <button mat-raised-button class="test-button" color="primary" (click)="testMyTotp()">Test</button>
              <button *ngIf="allowReset()" mat-raised-button class="test-button" color="primary" (click)="testMyTotp('enable')">Enable</button>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            *ngIf="showSMSPanel"
            [disabled]="!isSupportedMfaMethod(challengeType.sms)"
            [matTooltip]="restrictedMethodDisabledPanelTooltipText"
            [matTooltipDisabled]="isSupportedMfaMethod(challengeType.sms)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="scrollable-div">
                <mat-icon class="mfa-icon">sms</mat-icon>
                Send an SMS to your phone
                <mat-icon class="help-icon" [matTooltip]="smsTooltipText">help_outline</mat-icon>
              </mat-panel-title>
              <mat-panel-description *ngIf="smsEnabled">
                <mat-icon class="enabled-icon">check_circle</mat-icon>
                <span *ngIf="!isSmallScreen">Enabled</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Not currently available</p>
          </mat-expansion-panel>

          <mat-expansion-panel
            [disabled]="!isSupportedMfaMethod(challengeType.webauthn)"
            [matTooltip]="restrictedMethodDisabledPanelTooltipText"
            [matTooltipDisabled]="isSupportedMfaMethod(challengeType.webauthn)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="scrollable-div">
                <mat-icon class="mfa-icon">vpn_key</mat-icon>
                Use biometric or security key
                <mat-icon class="help-icon" [matTooltip]="registeredSecurityKeysTooltipText">help_outline</mat-icon>
              </mat-panel-title>
              <mat-panel-description *ngIf="isEnrolled(webauthnDeviceTableData)">
                <mat-icon class="enabled-icon">check_circle</mat-icon>
                <span *ngIf="!isSmallScreen">Enabled</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="!canUseWebAuthn()" class="scrollable-div" [innerHTML]="webAuthNDisabledReason"></div>

            <div *ngIf="canUseWebAuthn()" class="scrollable-div">
              <p class="text-left">
                If the device you're on is capable, you will be able to choose between using your device as a key for
                verification or using an external key.<br>
                If not, you must use an external key. You may also choose a device name.
                The name you choose will help you differentiate between keys if you have multiple.
              </p>
              <button *ngIf="!addingWebauthnDevice" mat-raised-button color="primary" class="set-up-button"
                (click)="setupWebauthn()">
                {{ getActiveWebauthnEnrollButtonText() }}
              </button>

              <div *ngIf="addingWebauthnDevice">
                <div class="authenticator-type-selector-container">
                  <mat-form-field class="authenticator-type-selector">
                    <mat-label>Authenticator Type</mat-label>
                    <mat-select (selectionChange)="authenticatorTypeSelection($event.value)"
                      value="{{ currentAuthenticatorType }}">
                      <mat-option *ngFor="let option of authenticatorTypeOptions" value="{{ option }}">
                        {{ option }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="text-left-warn" *ngIf="shouldDisplayAuthenticatorHelpMsg()" [innerHTML]="authenticatorHelpContent"></div>

                <div class="device-name-input-container">
                  <mat-form-field class="device-name-input" appearance="outline">
                    <mat-label>Device Name</mat-label>
                    <input matInput [(ngModel)]="currentWebauthnDeviceName" (keyup.enter)="enrollDeviceWithWebauthn()"
                      spellcheck="false" autocomplete="off" />
                  </mat-form-field>
                </div>

                <button mat-raised-button color="primary" class="register-button"
                  (click)="enrollDeviceWithWebauthn()">REGISTER</button>
              </div>

              <div class="device-table-container">
                <div class="device-table-title">
                  <h3>Enrolled Devices:</h3>
                </div>

                <div class="mat-elevation-z8">
                  <table mat-table #webauthnTableSort="matSort" [dataSource]="webauthnDataSource" matSort>
                    <ng-container matColumnDef="nickname">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Device Name
                      </th>
                      <td mat-cell *matCellDef="let row">
                        {{ row.nickname }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="origin">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Domain
                      </th>
                      <td mat-cell *matCellDef="let row">
                        {{ row.origin}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="created">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Date Added
                      </th>
                      <td mat-cell *matCellDef="let row">
                        {{ row.created }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="enabled">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Enabled
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <mat-icon *ngIf="row.enabled" class="enabled-icon">check_circle</mat-icon>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef class="actions-column-header">
                        Actions
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <button mat-icon-button [matMenuTriggerFor]="menu"
                          aria-label="Icon-button with a menu to choose an action for the selected row"
                          matTooltip="Click to select an option">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="testWebauthnChallengeMethodFromTable(row)">
                            <mat-icon>notifications</mat-icon>
                            <span>Test</span>
                          </button>
                          <button mat-menu-item (click)="deleteWebauthnChallengeMethodFromTable(row)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                          </button>
                          <button *ngIf="!row.enabled" mat-menu-item (click)="testWebauthnChallengeMethodFromTable(row,'enable')">
                            <mat-icon>refresh</mat-icon>
                            <span>Enable</span>
                          </button>
                        </mat-menu>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="webauthnDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: webauthnDisplayedColumns"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</mat-dialog-content>