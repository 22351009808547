<div *ngFor="let label of labelsList" class="icon-container">
    <div
      class="icon-inner-container"
      (click)="onLabelClick(label)"
    >
      <div
        *ngIf="showStatusIcons"
        class="mat-icon-status-container"
      >
        <!-- Empty div so folders align with resource icons -->
      </div>
      <div class="icon-img-container">
        <img
          *ngIf="label.icon_url"
          alt="icon"
          src="{{ label.icon_url }}"
        />
        <mat-icon
          *ngIf="!label.icon_url"
          class="default-icon"
          [ngClass]="{'default-icon-small': isSmallScreen}"
        >
          label
        </mat-icon>
      </div>
      <div class="resource-icon-text-container">
        <p>{{ label.name }}</p>
      </div>
    </div>
</div>
