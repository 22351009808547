<div *ngIf="!user" class="notification-icon-container-wrap">
  <mat-icon class="badge-icon">
    notifications_off
  </mat-icon>
</div>

<div *ngIf="!!user" class="notification-icon-container-wrap">
  <div [ngClass]="{ 'shake': unreadInboxItemsCount > 0 }">
    <mat-icon
      class="badge-icon clickable"
      (click)="getAllInboxItems()"
      matBadge="{{ unreadInboxItemsCount > 0 ? unreadInboxItemsCount : null }}"
      [matMenuTriggerFor]="badgeMenu"
      matBadgeColor="accent"
    >
      notifications
    </mat-icon>
  </div>
  
  <mat-menu
    #badgeMenu="matMenu"
    class="account-menu notifications-account-menu"
  >
    <div class="subscribe-header">
      <div style="width: 70%;">
        <button
          [class]="checkNotificationPermission() ? 'disable-button' : ''"
          type="button"
          mat-raised-button
          color="primary"
          class="control-button"
          (click)="subscribeToNotifications()"
          matTooltip="If you accidently rejected the push notifications and wish to accept it, please go to chrome://settings/content/notifications in your browser and scroll down to the 'Not allowed to send notifications' list which contains all the websites that are blocked from emitting push notifications
          -> delete host from this list -> Click the Subscribe button again"
        >
          Subscribe to Push Notifications
        </button>
      </div>
      <div *ngIf="inboxItems && inboxItems.length > 0" class="more-options">
        <button
          mat-icon-button
          [matMenuTriggerFor]="moreOptions"
          #menuTrigger="matMenuTrigger"
          (mouseover)="menuTrigger.openMenu()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #moreOptions="matMenu" [overlapTrigger]="false">
          <span (mouseleave)="menuTrigger.closeMenu()">
            <button mat-menu-item (click)="markAllInboxItemsAsRead(inboxItems)">Mark All As Read</button>
            <button mat-menu-item (click)="markAllInboxItemsAsUnRead(inboxItems)">Mark All As Unread</button>
            <button mat-menu-item (click)="deleteAllInboxItems(inboxItems)">Dismiss All</button>
          </span>
        </mat-menu>
      </div>
    </div>

    <div *ngIf="inboxItems && inboxItems.length < 1">
      <button style="min-width: 250px;" mat-menu-item>No notifications to display</button>
    </div>

    <div *ngIf="inboxItems && inboxItems.length > 0">
      <button
        *ngFor="let item of inboxItems"
        class="inbox-item-container"
        mat-menu-item
        [matMenuTriggerFor]="openMessage"
        [matMenuTriggerData]="{inboxItem : item}"
        [ngClass]="{'has-been-read' : item.spec.has_been_read}"
      >
        <div class="row">
          <div class="left-content">
            <span [ngClass]="{'bold-text' : !item.spec.has_been_read}">{{ item.status.message.title }}: </span>
            <span [innerHTML]="item.status.message.text"></span>
          </div>

          <div
            [ngClass]="{'bold-text' : !item.spec.has_been_read}"
            class="right-content"
          >
            {{ getUserFriendlyTime(item.metadata.created) }}
          </div>
        </div>
      </button>
    </div>
  </mat-menu>

  <mat-menu #openMessage="matMenu">
    <ng-template matMenuContent let-inboxItem="inboxItem">
      <button mat-menu-item (click)="openInboxItem(inboxItem)">Open</button>
      <button mat-menu-item *ngIf="!inboxItem.spec.has_been_read" (click)="updateInboxItemReadStatus(inboxItem)">Mark As Read</button>
      <button mat-menu-item *ngIf="inboxItem.spec.has_been_read" (click)="updateInboxItemReadStatus(inboxItem)">Mark As Unread</button>
      <button mat-menu-item (click)="deleteInboxItem(inboxItem)">Dismiss</button>
    </ng-template>
  </mat-menu>
</div>