import { Component, OnInit } from '@angular/core';
import {
  UsersService,
  ListUserApplicationAccessInfoRequestParams,
  LabelsService,
  ListLabelledObjectsRequestParams,
  ResourceTypeEnum,
  LabelledObject,
  UserFileShareAccessInfo,
  Label,
  ListObjectLabelsRequestParams,
} from '@agilicus/angular';
import { Observable, combineLatest, of } from 'rxjs';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { concatMap, map, catchError, switchMap, shareReplay } from 'rxjs/operators';
import { getEmptyResourceComponentState, getIconURIFromResourceDisplayInfo } from '../utils';
import { ResourceType } from '../resource-type.enum';
import { ResourceComponentState } from '../resource-component-state';
import { ResourceAccessInfo } from '@app/resource-access-info-type';

export interface LabelInfo {
  name: string;
  objects: LabelledObject[];
  selected?: boolean;
  icon_url?: string;
}

export interface LabelsByAccess {
  granted: LabelInfo[];
  requested: LabelInfo[];
  all: LabelInfo[];
}

@Component({
  selector: 'app-app-launcher',
  templateUrl: './app-launcher.component.html',
  styleUrls: ['./app-launcher.component.scss'],
})
export class AppLauncherComponent implements OnInit {
  public appAccessInfo$: Observable<ResourceComponentState>;
  public labelsInfo$: Observable<LabelsByAccess>;
  private userId = '';
  private userRootOrgId = '';
  private orgIdParam = '';
  public resourceType = ResourceType.application;
  private routerParams$: Observable<Params>;
  private labelNameToLabelledObjectMap = new Map<string, LabelledObject[]>();
  private labelNameToLabelMap = new Map<string, Label>();

  constructor(private route: ActivatedRoute, private users: UsersService, private router: Router, private labelsService: LabelsService) {
    this.routerParams$ = this.route.queryParams;
    this.appAccessInfo$ = this.getAppAccessInfo$().pipe(shareReplay(1));
    this.labelsInfo$ = this.appAccessInfo$.pipe(
      switchMap(({ orgIdParam, resourceAccessInfo }) => this.getLabelsInfo$(orgIdParam, resourceAccessInfo)),
      shareReplay(1)
    );
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  private setLabelNameToLabelMap(labelList: Array<Label>): void {
    this.labelNameToLabelMap.clear();
    for (const label of labelList) {
      this.labelNameToLabelMap.set(label.spec.name, label);
    }
  }

  private setLabelNameToLabelledObjectMap(labelledObjectList: Array<LabelledObject>): void {
    this.labelNameToLabelledObjectMap.clear();
    for (const object of labelledObjectList) {
      for (const label of object.labels) {
        if (!label.status?.navigation?.enabled) {
          continue;
        }
        if (!this.labelNameToLabelledObjectMap.has(label.label_name)) {
          this.labelNameToLabelledObjectMap.set(label.label_name, []);
        }
        this.labelNameToLabelledObjectMap.get(label.label_name).push(object);
      }
    }
  }

  private setLabelDataMaps(labelList: Array<Label>, labelledObjectList: Array<LabelledObject>): void {
    this.setLabelNameToLabelMap(labelList);
    this.setLabelNameToLabelledObjectMap(labelledObjectList);
  }

  private getObjectLabelsList$(labelsService: LabelsService, orgId: string | undefined): Observable<Array<Label>> {
    const listLabelledObjectsRequestParams: ListObjectLabelsRequestParams = {
      org_id: orgId,
    };
    return labelsService.listObjectLabels(listLabelledObjectsRequestParams).pipe(
      map((resp) => {
        return resp.labels;
      })
    );
  }

  private getLabelledObjectsList$(labelsService: LabelsService, orgId: string | undefined): Observable<Array<LabelledObject>> {
    const listLabelledObjectsRequestParams: ListLabelledObjectsRequestParams = {
      org_id: orgId,
    };
    const resourceTypesList = Object.values(ResourceTypeEnum);
    if (resourceTypesList.length !== 0) {
      listLabelledObjectsRequestParams.object_types = resourceTypesList;
    }
    return labelsService.listLabelledObjects(listLabelledObjectsRequestParams).pipe(
      map((resp) => {
        return resp.labelled_objects;
      })
    );
  }

  private getLabelsInfo$(orgId: string, resourceAccessInfo: ResourceAccessInfo[]): Observable<LabelsByAccess> {
    const objectLabelsList$ = this.getObjectLabelsList$(this.labelsService, orgId);
    const labelledObjectsList$ = this.getLabelledObjectsList$(this.labelsService, orgId);

    return combineLatest([objectLabelsList$, labelledObjectsList$]).pipe(
      map(([objectLabelsList, labelledObjectListResp]) => {
        this.setLabelDataMaps(objectLabelsList, labelledObjectListResp);
        const result = this.buildLabelAccessMap(resourceAccessInfo);
        result.granted.sort((a, b) => b.objects.length - a.objects.length);
        result.requested.sort((a, b) => b.objects.length - a.objects.length);
        result.all.sort((a, b) => b.objects.length - a.objects.length);
        return result;
      })
    );
  }

  private getAppAccessInfo$(): Observable<ResourceComponentState> {
    return this.routerParams$.pipe(
      concatMap((routerParamsResp) => {
        this.orgIdParam = routerParamsResp.org_id;
        this.userId = routerParamsResp.user_id || localStorage.getItem('user_id');
        const storedOrgId = localStorage.getItem('org_id');
        this.userRootOrgId = storedOrgId !== null ? storedOrgId : '';
        const currentOrgId = this.orgIdParam || this.userRootOrgId;
        const queryParams = { org_id: currentOrgId };

        if (routerParamsResp.vncdialog === 'true') {
          queryParams['vncdialog'] = 'true';
        }

        this.router.navigate(['/applications'], {
          queryParams,
        });
        this.router.navigate([`/${this.resourceType}s`], {
          queryParams,
        });

        const params: ListUserApplicationAccessInfoRequestParams = {
          user_id: this.userId,
          org_id: this.userRootOrgId,
        };

        const appAccess$ = this.users.listUserApplicationAccessInfo(params).pipe(
          map((appAccessresp) => {
            const resourceComponentState: ResourceComponentState = {
              resourceAccessInfo: appAccessresp.user_application_access_info,
              userRootOrgId: this.userRootOrgId,
              userId: this.userId,
              orgIdParam: this.orgIdParam,
              title: '',
            };
            return resourceComponentState;
          })
        );

        const resourceAccess$ = this.users.listUserResourceAccessInfo(params).pipe(
          map((resourceAccessResp) => {
            const resourceComponentState: ResourceComponentState = {
              resourceAccessInfo: resourceAccessResp.user_resource_access_info,
              userRootOrgId: this.userRootOrgId,
              userId: this.userId,
              orgIdParam: this.orgIdParam || localStorage.getItem('org_id'),
              title: '',
            };
            return resourceComponentState;
          })
        );

        const combined$ = combineLatest([appAccess$, resourceAccess$]).pipe(
          map(([appAccess, resourceAccess]) => ({
            resourceAccessInfo: [...appAccess.resourceAccessInfo, ...resourceAccess.resourceAccessInfo],
            userRootOrgId: this.userRootOrgId,
            userId: this.userId,
            orgIdParam: this.orgIdParam || localStorage.getItem('org_id'),
            title: 'Applications',
          }))
        );

        return combined$;
      }),
      catchError((_) => {
        return of(getEmptyResourceComponentState());
      })
    );
  }

  private buildLabelAccessMap(resourceAccessInfo: ResourceAccessInfo[]): LabelsByAccess {
    const result: LabelsByAccess = {
      granted: [],
      requested: [],
      all: [],
    };

    for (const [key, val] of this.labelNameToLabelledObjectMap) {
      const grantedObjects: LabelledObject[] = getObjectsOfAccessLevel(val, resourceAccessInfo, 'granted');
      const requestedObjects: LabelledObject[] = getObjectsOfAccessLevel(val, resourceAccessInfo, 'requested');
      const allObjects: LabelledObject[] = [
        ...grantedObjects,
        ...requestedObjects,
        ...getObjectsOfAccessLevel(val, resourceAccessInfo, 'none'),
      ];
      const targetLabel = this.labelNameToLabelMap.get(key);
      const targetIconURI = getIconURIFromResourceDisplayInfo(targetLabel?.spec?.display_info);
      // We only care about labels of a given type which have resources. Filter them out.
      if (grantedObjects.length > 0) {
        result.granted.push({
          name: key,
          objects: grantedObjects,
          icon_url: targetIconURI,
        });
      }
      if (requestedObjects.length > 0) {
        result.requested.push({
          name: key,
          objects: requestedObjects,
          icon_url: targetIconURI,
        });
      }
      if (allObjects.length > 0) {
        result.all.push({
          name: key,
          objects: allObjects,
          icon_url: targetIconURI,
        });
      }
    }

    return result;
  }
}

function isShareAccess(info: any): info is UserFileShareAccessInfo {
  return info?.status?.share_id !== undefined;
}

function getObjectsOfAccessLevel(objects: LabelledObject[], resourceAccessInfo: ResourceAccessInfo[], accessLevel: string) {
  return objects.filter((obj: LabelledObject) =>
    resourceAccessInfo.some((info: ResourceAccessInfo) => {
      const obj_id = isShareAccess(info) ? info.status.share_id : info.status.resource_id;
      return info.status.access_level == accessLevel && obj.org_id === info.status.org_id && obj.object_id === obj_id;
    })
  );
}
