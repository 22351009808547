import {
  InboxItem,
  MessageEndpoint,
  MessageEndpointsConfig,
  MessagesService,
  UpdateMessageEndpointRequestParams,
  User,
} from '@agilicus/angular';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  catchError,
  combineLatest,
  concatMap,
  forkJoin,
  interval,
  map,
  mergeMap,
  Observable,
  of,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { detect } from 'detect-browser';
import { NotificationService } from './notification.service';
import { SwPush } from '@angular/service-worker';
import { ActivatedRoute, Params } from '@angular/router';
import { ChallengeType } from '@app/mfa-enroll/mfa-enroll.component';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { InboxMessageDialogComponent } from '@app/inbox-message-dialog/inbox-message-dialog.component';
import { InboxItemsService } from '@app/inbox-items.service';
import { isIosMobileDevice } from '@app/browser-utils';
import { PwaService } from '@app/services/pwa.service';
import {
  getUnreadSurveyMessage,
  needToSetSurveyMessageLastTimeChecked,
  setSurveyMessageLastTimeChecked,
  shouldOpenUnreadSurveyMessage,
} from '@app/inbox-item-utils';
import { EventsService } from '@app/services/event-service/events.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public user: User;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasPermissions: boolean;
  public inboxItems: Array<InboxItem>;
  private unreadInboxItemsCount$: Observable<number>;
  public unreadInboxItemsCount = 0;
  private cachedUnreadInboxItemCount = 0;
  private vapidPublicKey = '';
  public subtoken = '';
  private browser = detect();
  public accessToken = '';
  public webPushEnabled: boolean;
  public enrollMessageSent = false;
  private routerParams$: Observable<Params>;
  private inboxItemsData$: Observable<Array<InboxItem>>;
  private localUserCache: User;
  private startedPollingInboxItems = false;
  private listMessageConfig$: Observable<MessageEndpointsConfig>;

  constructor(
    public dialog: MatDialog,
    private inboxItemsService: InboxItemsService,
    private messagesService: MessagesService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private swPush: SwPush,
    private route: ActivatedRoute,
    private pwaService: PwaService,
    private eventsService: EventsService
  ) {
    this.routerParams$ = this.route.queryParams;
    this.webPushEnabled = this.swPush.isEnabled;
  }

  public ngOnInit(): void {
    this.getAndSetAllData();
  }

  public ngOnChanges(): void {
    if (!this.localUserCache && !!this.user) {
      this.getAndSetAllData();
      this.localUserCache = this.user;
    }
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAndSetAllData(): void {
    // Cancel the previous subscription before subscribing again:
    this.unsubscribe$.next();
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([inboxItemsDataResp, unreadInboxItemsCountResp, listMessageConfigResp]) => {
        if (listMessageConfigResp) {
          if (listMessageConfigResp.web_push && listMessageConfigResp.web_push.public_key) {
            this.vapidPublicKey = listMessageConfigResp.web_push.public_key;
          }
        } else {
          this.notificationService.error(
            'A network error prevented fetching the messaging keys. You will be unable to add a new web push endpoint. You may browse or change the existing methods, or refresh the browser to retry.'
          );
        }
        if (unreadInboxItemsCountResp !== this.cachedUnreadInboxItemCount) {
          // The number from the polling has changed, so this is the most up to date number
          this.unreadInboxItemsCount = unreadInboxItemsCountResp;
          this.cachedUnreadInboxItemCount = unreadInboxItemsCountResp;
        }
        this.inboxItems = inboxItemsDataResp;
        const unreadSurveyMessage = getUnreadSurveyMessage(this.inboxItems);
        if (!!unreadSurveyMessage && needToSetSurveyMessageLastTimeChecked()) {
          setSurveyMessageLastTimeChecked();
          if (shouldOpenUnreadSurveyMessage(unreadSurveyMessage)) {
            this.delayedOpenInboxItem(unreadSurveyMessage);
            this.eventsService.SendEvent({
              event_id: 'survey-message-automatically-opened',
              event_type: 'open',
              category: 'dialog',
              sub_category: 'survey',
            });
          }
        }
        this.changeDetector.detectChanges();
      });
  }

  private getAllData$() {
    return this.routerParams$.pipe(
      concatMap((routerParamsResp) => {
        const orgId = routerParamsResp.org_id || localStorage.getItem('org_id');
        const userId = routerParamsResp.user_id || localStorage.getItem('user_id');
        if (!orgId) {
          this.inboxItemsData$ = of([]);
        } else {
          this.inboxItemsData$ = this.inboxItemsService.getInboxItemsListUsingAuthService$(orgId);
        }
        this.listMessageConfig$ = this.messagesService.listMessagesConfig();
        if (!this.startedPollingInboxItems) {
          if (!orgId || !userId) {
            this.unreadInboxItemsCount$ = of(0);
          } else {
            this.unreadInboxItemsCount$ = interval(30 * 1000)
              .pipe(startWith(0))
              .pipe(mergeMap(() => this.inboxItemsService.getUnreadInboxItemsCount$(userId, orgId)));
            this.startedPollingInboxItems = true;
          }
        }
        return combineLatest([this.inboxItemsData$, this.unreadInboxItemsCount$, this.listMessageConfig$]);
      })
    );
  }

  public delayedOpenInboxItem(item: InboxItem): void {
    setTimeout(() => {
      this.openInboxItem(item);
    }, 2000);
  }

  public openInboxItem(item: InboxItem): void {
    const dialogData: InboxItem = item;
    const dialogRef = this.dialog.open(InboxMessageDialogComponent, {
      data: dialogData,
    });

    if (!item.spec.has_been_read) {
      this.updateInboxItemReadStatus(item);
    }
  }

  public deleteInboxItem(item: InboxItem): void {
    this.inboxItemsService
      .deleteInboxItemUsingAuthService$(item.metadata.id)
      .pipe(take(1))
      .subscribe(() => {
        this.getAndSetAllData();
      });
  }

  private updateWebPushMessageEndpoint$(sub: PushSubscription): Observable<MessageEndpoint | undefined> {
    const sub1 = JSON.stringify(sub);
    this.subtoken = sub1;
    const mpa: UpdateMessageEndpointRequestParams = {
      user_id: this.user.id,
      MessageEndpoint: {
        spec: {
          endpoint_type: ChallengeType.web_push,
          nickname: this.browser?.name + '-' + this.browser?.os + '-' + this.browser?.version,
          address: sub1,
        },
      },
    };
    return this.messagesService.updateMessageEndpoint(mpa).pipe(
      map((resp) => {
        return resp;
      }),
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the message endpoint id.');
        return of(undefined);
      })
    );
  }

  public subscribeToNotifications(): void {
    if (isIosMobileDevice()) {
      this.notificationService.info(
        'Push notifications are not supported in this browser. To enable them, please add this web app to your home screen.'
      );
      this.pwaService.triggerIosPromptManually('ios');
    }

    this.swPush
      .requestSubscription({
        serverPublicKey: this.vapidPublicKey,
      })
      .then((sub) => this.updateWebPushMessageEndpoint$(sub).pipe(take(1)).subscribe())
      .catch((err) => this.notificationService.error('Could not subscribe to notifications ' + err));
  }

  public getUserFriendlyTime(date: Date): string {
    return moment(new Date(date)).fromNow();
  }

  public checkNotificationPermission(): boolean {
    return 'Notification' in window && Notification.permission === 'granted';
  }

  public updateInboxItemReadStatus(item: InboxItem): void {
    item.spec.has_been_read = !item.spec.has_been_read;
    this.inboxItemsService
      .replaceInboxItemUsingAuthService$(item.metadata.id, item)
      .pipe(take(1))
      .subscribe(() => {
        this.getAndSetAllData();
      });
  }

  public getAllInboxItems(): void {
    this.getAndSetAllData();
  }

  public markAllInboxItemsAsRead(inboxItems: Array<InboxItem>): void {
    const filteredItems = inboxItems.filter((item) => !item.spec.has_been_read);

    const updatedItems = filteredItems.map((item) => {
      return {
        ...item,
        spec: {
          ...item.spec,
          has_been_read: true,
        },
      };
    });

    this.replaceManyInboxItems(updatedItems);
  }

  public markAllInboxItemsAsUnRead(inboxItems: Array<InboxItem>): void {
    const filteredItems = inboxItems.filter((item) => item.spec.has_been_read);

    const updatedItems = filteredItems.map((item) => {
      return {
        ...item,
        spec: {
          ...item.spec,
          has_been_read: false,
        },
      };
    });
    this.replaceManyInboxItems(updatedItems);
  }

  public replaceManyInboxItems(updatedItems: Array<InboxItem>): void {
    const observablesArray$: Array<Observable<InboxItem>> = [];
    for (const item of updatedItems) {
      observablesArray$.push(this.inboxItemsService.replaceInboxItemUsingAuthService$(item.metadata.id, item));
    }
    if (observablesArray$.length === 0) {
      return;
    }
    forkJoin(observablesArray$)
      .pipe(take(1))
      .subscribe(() => {
        this.getAndSetAllData();
      });
  }

  public deleteAllInboxItems(inboxItems: Array<InboxItem>): void {
    const observablesArray$: Array<Observable<void>> = [];
    for (const item of inboxItems) {
      observablesArray$.push(this.inboxItemsService.deleteInboxItemUsingAuthService$(item.metadata.id));
    }
    if (observablesArray$.length === 0) {
      return;
    }
    forkJoin(observablesArray$)
      .pipe(take(1))
      .subscribe(() => {
        this.getAndSetAllData();
      });
  }
}
